import fitty from 'fitty';

class FitText {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-fittext'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$fitText = $element;

        this.initialize();
    }

    initialize () {
        this.fittyAPI = fitty(this.$fitText);
        this.fittyElement = this.fittyAPI.element;
    }
}

export { FitText };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$fitText = $context.querySelectorAll('[data-fittext');
        for (let i = 0; i < $$fitText.length; i++) {
            const $fitText = $$fitText[i];

            const fitTextAPI = new FitText($fitText);
            $fitText.API = fitTextAPI;
        }
    }
});
